import React from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import uuid from 'react-uuid';
import { IFooterBase } from './store';
import ChevronRight from '~/common/components/ui-elements/svg-icon/svgs/ChevronRight';
export const FooterCTAsection = (props: IFooterBase) => {
  return (
    <>{props.showFooter?.toLowerCase() === 'true' && <Results {...props} />}</>
  );
};
const FirstCTA = (props: IFooterBase) => (
  <div className={styles.BrocAndDlr2_inner_btn1}>
    <div className={styles.BrocAndDlr2_inner_btn1_container}>
      <div>
        <a
          href={props.firstCTALink}
          className={styles.BrocAndDlr2_inner_btn1_link}
        >
          <span className={styles.BrocAndDlr2_inner_btncta_container}>
            <span className={styles.BrocAndDlr2_inner_btncta}>
              {props.firstCTAText}
            </span>
            <i>
              <ChevronRight color="#222222" className={styles.ChevronRight} />
            </i>
          </span>
        </a>
        <p
          className={styles.BrocAndDlr2_inner_btn1_learnmore}
          dangerouslySetInnerHTML={{
            __html: props.firstCTALearnMore as string,
          }}
        />
      </div>
    </div>
  </div>
);
const SecondCTA = (props: IFooterBase) => (
  <div className={styles.BrocAndDlr2_inner_btn2}>
    <div className={styles.BrocAndDlr2_inner_btn2_container}>
      <div>
        <a
          href={props.secondCTALink}
          className={styles.BrocAndDlr2_inner_btn2_link}
        >
          <span className={styles.BrocAndDlr2_inner_btncta_container}>
            <span className={styles.BrocAndDlr2_inner_btncta}>
              {props.secondCTAText}
            </span>
            <i>
              <ChevronRight color="#222222" className={styles.ChevronRight} />
            </i>
          </span>
        </a>
        <p
          className={styles.BrocAndDlr2_inner_btn2_learnmore}
          dangerouslySetInnerHTML={{
            __html: props.secondCTALearnMore as string,
          }}
        />
      </div>
    </div>
  </div>
);
const Results = (props: IFooterBase) => (
  <div key={uuid()} className={cn('full-device-width', styles.BrocAndDlr2)}>
    <div className={styles.BrocAndDlr2_inner}>
      <div className={styles.BrocAndDlr_container}>
        <div className={styles.BrocAndDlr_container_box1}>
          <div className={styles.BrocAndDlr2_inner1}>
            <img src={props.image} className={styles.BrocAndDlr2_inner_img} />
          </div>
        </div>
        <div className={styles.BrocAndDlr_container_box2}>
          <div className={styles.BrocAndDlr2_inner_ctas}>
            {props.showFirstCTA?.toLowerCase() === 'true' && (
              <FirstCTA {...props} />
            )}
            {props.showSecondCTA?.toLowerCase() === 'true' && (
              <SecondCTA {...props} />
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);
